import $ from 'jquery';
import validate from 'jquery-validation';

$(function () {
    if ($('.site-settings').length) {
        $('#userPassword form').validate({
            rules: {
                userPassword: {
                    required: true,
                    minlength: 5
                },
                userConfirmPassword: {
                    equalTo: '[name="userPassword"]'
                }
            },
            messages: {
                userPassword: {
                    required: 'Please provide a password',
                    minlength: 'Your password must be at leas 5 characters long'
                },
                userConfirmPassword: {
                    equalTo: 'Please enter the same password'
                }
            },
            submitHandler: function (form) {
                var $form = $(form);
                var data = $form.serialize();
                $.ajax({
                    url: $form.attr('data-action'),
                    type: 'POST',
                    data: data,
                    success: function () {
                        form.reset();
                        $form.parent().addClass('success');

                        setTimeout(function () {
                            $('#changeUserPassword').trigger('click');  
                            setTimeout(function () {
                                $form.parent().removeClass('success');
                            }, 500);
                        }, 2000);  
                    }
                });
            }
        });

        $('#userEmail form').validate({
            rules: {
                userEmail: {
                    required: true,
                    email: true
                },
                userConfirmEmail: {
                    email: true,
                    equalTo: '[name="userEmail"]'
                }
            },
            messages: {
                userEmail: {
                    required: 'Please provide an email'
                },
                userConfirmEmail: {
                    equalTo: 'Please enter the same email'
                }
            },
            submitHandler: function (form) {
                var $form = $(form);
                var data = $form.serialize();
                $.ajax({
                    url: $form.attr('data-action'),
                    type: 'POST',
                    data: data,
                    success: function () {
                        form.reset();
                        $form.parent().addClass('success');

                        setTimeout(function () {
                            $('#changeUserEmail').trigger('click');
                            setTimeout(function () {
                                $form.parent().removeClass('success');
                            }, 500);
                        }, 2000);
                    }
                });
            }
        });
    }
});