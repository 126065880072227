import $ from 'jquery';

export default function formToJson($elements) {
    var dataDictionary = formToDictionary($elements);
    return JSON.stringify(dataDictionary);
};

const formToDictionary = elements => [].reduce.call(elements, (data, element) => {

    if (element.hasAttribute('data-complex')) {
        let complexProperty = element.getAttribute('data-complex');

        if (!data.hasOwnProperty(complexProperty))
            data[complexProperty] = {};

        data[complexProperty][element.name] = element.value;
    }
    else {
        data[element.name] = element.value;
    }
   
    return data;
}, {});