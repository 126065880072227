import $ from 'jquery';
import Highcharts from 'highcharts/highcharts';
import FileUploader from '../shared/fileUploader';
import StatusPanel from '../shared/statusPanel';
//import GreyTheme from 'highcharts/themes/gray';

$(function () {
    if ($('#data_files').length) {
        
        var fileStatuses = {
            new: 0,
            error: 1,
            processed: 2,
            viewed: 3,
            emailed: 4,
            archived: 5
        };

        var fileUploader; 

        var charts = [];

        var $fileViewer = $('[data-modal-dialog="file-viewer"]');
        var $fileViewerHeader = $fileViewer.find('.custom-header h6');
        var $fileViewerBody = $fileViewer.find('.custom-body');

        var fileTable = $("#data_files table").DataTable({
            dom: '<"config-toolbar"Bf>rtp',
            buttons: [
                {
                    text: '<i class="fas fa-cloud-upload-alt" data-toggle="tooltip" data-placement="top" title="Upload file"></i>',
                    attr: {
                        'data-action': 'upload'
                    },
                    action: uploadFile
                },
                {
                    text: '<i class="fas fa-filter" data-toggle="tooltip" data-placement="top" data-container="body" title="Filters"></i>',
                    action: setFileFilters
                },
                {
                    text: '<i class="fas fa-archive" data-toggle="tooltip" data-placement="top" data-container="body" title="Archive"></i>',
                    action: archiveFiles
                }
            ],
            columns: [
                { data: 'started', width: '15%', type: 'date' },
                { data: 'processed', width: '15%', type: 'date' },
                { data: 'fileName' },
                { data: 'fileType' },               
                { data: 'location' },
                { data: 'sublocation' },
                { data: 'device' },
                {
                    defaultContent: '',
                    className: 'text-center status',
                    createdCell: function (td, cellData, rowData, row, col) {
                        if (rowData.processCode === fileStatuses['processed']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-check fa-inverse"></i>');
                        }
                        else if (rowData.processCode === fileStatuses['new']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-question fa-inverse"></i>');
                        }
                        else if (rowData.processCode === fileStatuses['error']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-times fa-inverse"></i>');
                        }
                        else if (rowData.processCode === fileStatuses['viewed']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-eye fa-inverse"></i>');
                        }
                        else if (rowData.processCode === fileStatuses['emailed']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-envelope fa-inverse"></i>');
                        }
                        else if (rowData.processCode === fileStatuses['archived']) {
                            $(td).attr("data-order", rowData.processCode).html('<i class="fas fa-archive fa-inverse"></i>');
                        }
                    }
                },
                {
                    orderable: false,
                    searchable: false,
                    className: 'tools',
                    width: '70px',
                    defaultContent:
                        `<div class="file-controls">
                            <i class="fas fa-cloud-download-alt mr-2"></i>
                            <i class="fas fa-envelope mr-2"></i>
                            <i class="fas fa-archive"></i>
                        </div>`
                }
            ],
            pageResize: true,
            createdRow: function (row, data, index) {
                $(row).attr('data-file-id', data.fileId);
            },
            ajax: {
                url: "/api/file/list",
                type: "GET",
                dataSrc: ""
            },
            order: [[0, "desc"]],
            initComplete: function () {
                $('[data-toggle="tooltip"]').tooltip();
            }
        });

        //File Uploader
        $('#data_files .config-toolbar').on('file-uploaded', '[data-action="upload"]', function (e, result) {
            var statusPanel = new StatusPanel();
            if (result.success) {
                statusPanel.showSuccessStatus(result.message);
                fileTable.ajax.reload();
            }
            else
                statusPanel.showFailureStatus(result.message);
        });

        //File Viewer
        $('#data_files').on('dblclick', 'tbody', function (e) {
            if (e.target.matches('td[preview-enabled="true"]')) {
                var fileId = $(e.target).closest('tr').attr('data-file-id');

                $.magnificPopup.open({
                    items: {
                        src: '[data-modal-dialog="file-viewer"]',
                        type: 'inline'
                    },
                    removalDelay: 300,
                    callbacks: {
                        beforeOpen: function () {
                            this.st.mainClass = 'mfp-zoom-in bg-black';
                        },
                        open: function () {
                            var $content = $(this.content);

                            $content.removeClass('initialized');

                            $.ajax({
                                url: '/api/file/data/' + fileId,
                                success: function (data) {
                                    $content.find('h6').html(`<span>File:</span>${data.fileName}<span>Type:</span>${data.fileType}<span>Rate:</span>${data.rate} Hz<span>Location:</span>${data.location}<span>Sublocation:</span>${data.sublocation}<span>Local Time:</span>${data.started}<span>Status:</span>${data.StatusMessage}`);

                                    $.each(data.channels, function (chIndex, channel) {
                                        var series = [];
                                        $.each(channel.phases, function (phIndex, phase) {
                                            series[phIndex] = {
                                                name: 'Phase ' + phase.name,
                                                data: (function () {
                                                    var dpSet = [];
                                                    $.each(phase.dataPoints, function (index, dataPoint) {
                                                        dpSet.push([1 / data.rate * index, parseFloat(dataPoint)]);
                                                    });

                                                    return dpSet;
                                                })()
                                            };
                                        });

                                        $(`<div class="chart" id="chart-${chIndex}"></div>`).appendTo($fileViewerBody);
                                        var chart = Highcharts.chart(`chart-${chIndex}`, {
                                            chart: {
                                                marginRight: 40,
                                                marginLeft: 60,
                                                width: $fileViewerBody.width(),
                                                zoomType: 'x',
                                                //resetZoomButton: {
                                                //    theme: {
                                                //        display: 'none'
                                                //    }
                                                //},
                                                events: {
                                                    //selection: function (event) {
                                                    //    if (typeof event.xAxis !== typeof undefined)
                                                    //        ZoomAllCharts(event);
                                                    //}
                                                    //load: function (event) {
                                                    //    event.target.reflow();
                                                    //}
                                                }
                                            },
                                            title: {
                                                text: channel.name,
                                                align: 'right',
                                                verticalAlign: 'top',
                                                x: -15,
                                                style: { color: 'white', fontSize: "22px", fontWeight: 'bold' }
                                            },
                                            credits: {
                                                enabled: false
                                            },
                                            legend: {
                                                enabled: true,
                                                align: 'center',
                                                verticalAlign: 'top'
                                            },
                                            xAxis: {
                                                crosshair: true,
                                                minPadding: 0,
                                                maxPadding: 0,
                                                //events: {
                                                //    setExtremes: syncExtremes
                                                //},
                                                labels: {
                                                    format: '{value} s',
                                                    style: { color: 'grey' }
                                                }
                                            },
                                            yAxis: {
                                                //min: dataset.ymin,
                                                //max: dataset.ymax,
                                                title: { text: null },
                                                labels: {
                                                    style: { color: 'grey' }
                                                }
                                            },
                                            tooltip: {
                                                shared: true,
                                                useHTML: true,
                                                borderColor: 'black',
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                headerFormat: '<div style="color: white; margin-bottom: 5px;"><b><span  style="float:left;">t:</span>' +
                                                    '<span style="float:right;">{point.x} s</span></b></div></br><table>',
                                                pointFormat: '<tr><td style="color: {series.color}"><b>{series.name}: <b></td>' +
                                                    '<td style="text-align: right"><b>{point.y}</b></td></tr>',
                                                footerFormat: '</table>',
                                                shadow: false,
                                                style: {
                                                    fontSize: '12px'
                                                },
                                                valueDecimals: 3,
                                                positioner: function (w, h, p) {
                                                    return {
                                                        x: (function () {
                                                            if (p.plotX <= w)
                                                                return p.plotX + 0.8 * w;
                                                            else
                                                                return p.plotX - 0.8 * w;
                                                        })(),
                                                        y: p.plotY
                                                    };
                                                }
                                            },
                                            exporting: {
                                                enabled: false
                                                //chartOptions: {
                                                //    plotOptions: {
                                                //        series: {
                                                //            dataLabels: {
                                                //                enabled: true
                                                //            }
                                                //        }
                                                //    }
                                                //},
                                                //scale: 3,
                                                //fallbackToExportServer: false
                                            },
                                            series: series
                                        });

                                        charts.push(chart);
                                    });

                                    $content.addClass('initialized');

                                    fileTable.ajax.reload();
                                }
                            });
                        },
                        close: function () {
                            charts.forEach(function (chart) {
                                chart.destroy();
                            });

                            charts = [];

                            $fileViewerHeader.html('');
                            $fileViewerBody.html('');
                        }
                    },
                    midClick: true,
                    closeOnBgClick: false,
                    enableEscapeKey: false
                });
                //fileTable.ajax.reload();
            }
        });

        //Tools
        $('#data_files').on('click', 'td', function (e) {
            var fileId = $(this).closest('tr').attr('data-file-id');
            var fileName = fileTable.row($(this).closest('tr')).data().fileName;

            //download
            if ($(e.target).closest('.fa-cloud-download-alt').length) {
                $('#file_downloader').attr('src', '/api/file/download/' + fileId);
            }
            //archive
            else if (!$(this).hasClass('status') && $(e.target).closest('.fa-archive').length) {
                $.magnificPopup.open({
                    items: {
                        src: '[data-modal-dialog="file-archive"]',
                        type: 'inline'
                    },
                    removalDelay: 300,
                    callbacks: {
                        beforeOpen: function () {
                            this.st.mainClass = 'mfp-zoom-in bg-black';
                        },
                        open: function () {
                            var $content = $(this.content);

                            $content.attr('data-file-id', fileId);
                            $content.find('[file-name]').html(fileName);
                        }
                    },
                    midClick: true,
                    closeOnBgClick: false,
                    enableEscapeKey: false,
                    modal: true
                });
            }
            //send
            else if ($(e.target).closest('.fa-envelope').length) {
                $.magnificPopup.open({
                    items: {
                        src: '[data-modal-dialog="file-email"]',
                        type: 'inline'
                    },
                    removalDelay: 300,
                    callbacks: {
                        beforeOpen: function () {
                            this.st.mainClass = 'mfp-zoom-in bg-black';
                        },
                        open: function () {
                            var $content = $(this.content);
                            $content.find('[name="fileName"]').val(fileName);

                            $('[data-modal-dialog="file-email"]').on('click', function (e) {
                                if (e.target.matches('button[type=reset]')) {
                                    e.preventDefault();
                                    $('[data-modal-dialog="file-email"]').off();
                                    $.magnificPopup.close();
                                }
                                else if (e.target.matches('button[type="submit"]')) {                                  
                                    sendFile($content.find('form').serialize(), $(this)); 
                                }
                            });
                        },
                        afterClose: function () {
                            $('[data-modal-dialog="file-email"]').off();
                            $('[data-modal-dialog="file-email"]').removeClass('sending sent-success sent-failure');
                            $('[data-modal-dialog="file-email"] form')[0].reset();
                        }
                    },
                    midClick: true,
                    closeOnBgClick: false,
                    enableEscapeKey: false,
                    modal: true
                });
            }
        });

        //Archive file
        $('[data-modal-dialog="file-archive"]').on('click', function (e) {
            if (e.target.matches('button[type="submit"]')) {
                $.ajax({
                    url: '/api/file/archive/' + $(this).attr('data-file-id'),
                    type: 'POST',
                    success: function () {
                        fileTable.ajax.reload();
                        $.magnificPopup.close();
                    }
                });
            }
            else if (e.target.matches('button[type="reset"]')) {
                $.magnificPopup.close();
            }
        });      
    }

    //Helpers

    function setFileFilters() {
        $.magnificPopup.open({
            items: {
                src: $('[data-modal-dialog="filters"]'),
                type: 'inline'
            },
            removalDelay: 300,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = 'mfp-zoom-in';
                },
                open: function () {
                    var $content = $(this.content);

                    $('[data-modal-dialog="filters"]')
                        .on('click', '[type = "reset"]', function (e) {
                            $('[data-modal-dialog="filters"]').off();
                            $.magnificPopup.close();
                        })
                        .on('click', '[type = "submit"]', function (e) {

                            var b = document.cookie.match('(^|[^;]+)\\s*' + 'file-filters' + '\\s*=\\s*([^;]+)');
                            var filters = JSON.parse(b.pop());
                            $('[data-modal-dialog="filters"] input:checkbox').each(function () {
                                var filter = $(this).attr('name');
                                filters[filter] = $(this).prop("checked");
                            });

                            var d = new Date();
                            d.setTime(d.getTime() + (3650 * 24 * 60 * 60 * 1000));
                            var expires = "expires=" + d.toUTCString();
                            document.cookie = 'file-filters' + "=" + JSON.stringify(filters) + ";" + expires + ";path=/";

                            fileTable.ajax.reload();
                            $('[data-modal-dialog="filters"]').off();
                            $.magnificPopup.close();
                        });
                },
                afterClose: function () {

                    //$content.off('submit');
                }
            },
            midClick: true,
            closeOnBgClick: false,
            enableEscapeKey: false,
            modal: true
        });
    }

    function uploadFile(e) {

        if (!fileUploader) {
            fileUploader = new FileUploader({
                title: 'Upload file(s)',
                filter: '.fbd, .ft1, .ft2, .ft3, .ff1, .ff2',
                allowMultiple: true,
                caller: $(e.currentTarget)
            });
        }

        fileUploader.open('/api/file/upload'); 
    }

    function archiveFiles() {
        $.magnificPopup.open({
            items: {
                src: $('[data-modal-dialog="file-archiver"]'),
                type: 'inline'
            },
            removalDelay: 300,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = 'mfp-zoom-in';
                },
                open: function () {
                    var $content = $(this.content);
                },
                afterClose: function () {

                    //$content.off('submit');
                }
            },
            midClick: true,
            closeOnBgClick: false,
            enableEscapeKey: false,
            modal: true
        });
    }

    function sendFile(email, $form) {

        $form.find('input, textarea, button:not([type=button])').prop('disabled', true);
        $form.addClass('sending');

        $.ajax({
            url: '/api/file/send',
            type: "POST",
            data: email
        }).done(function (response) {
            $form.addClass('sent-success');
            fileTable.ajax.reload();
        }).fail(function (){
            $form.addClass('sent-failure');
        })
        .always(function () {
            $form.find('input, textarea, button:not([type=button])').prop('disabled', false);
            $form.removeClass('sending');

            //start with delay
            setTimeout(() => {
               

                $.magnificPopup.close();
            }, 2500);
        });       
    }
});