import $ from 'jquery';
import ConfigList from '../configList';

export default class FftConfigList extends ConfigList {

    constructor(host) {

        super(host);

        this.selectors.selectDialog = '[data-modal-dialog="admin-fft-select"]';
        this.selectors.generateButton = '[data-button="generate"]';
        this.selectors.confirmSelectButton = '[data-button="confirm-select"]';

        this.createDialogs();

        this.$buttons.generate = this.$host.find(this.selectors.generateButton);
        this.$buttons.confirmSelect = this.$dialogs.select.find(this.selectors.confirmSelectButton);
    }

    bind() {

        super.bind();

        //----- Clone from existing instance
        this.$buttons.generate.on('click', (e) => {

            let _self = this;

            $.magnificPopup.open({
                items: {
                    src: this.$dialogs.select,
                    type: 'inline'
                },
                removalDelay: 300,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = 'mfp-zoom-in';
                    },
                    open: function () {

                        let $content = $(this.content);
                       
                        let $select = $content.find('select');
                        $select.empty();

                        let $label = $content.find('label');
                        let url = '';

                        if (_self.configType === 'nameplates') {
                            $label.text('Create from existing nameplate');
                            url = `/api/admin/fft/nameplates`;

                            //_self.$list.find('li[data-id]').each((i, el) => {
                            //    $select.append(`<option value='${el.getAttribute('data-id')}'>${el.innerText}</option>`);
                            //});
                        }
                        else if (_self.configType === 'freqbands') {
                            $label.text('Generate from existing nameplate');
                            url = `/api/admin/fft/nameplates`;
                        }
                        else if (_self.configType === 'configurations') {
                            $label.text('Select location');
                            url = `/api/admin/fft/configurations?status=noconfig`;
                        }

                        $.get({
                            url: url,
                            dataType: "json"
                        }).done(data => {
                            $select.empty();

                            var elements = $.parseJSON(data);
                            if (elements.length !== 0) {
                                elements.forEach(element => {
                                    $select.append(`<option value=${element.id}>${element.name}</option>`);
                                });
                            }
                            else {
                                $select.append(`<option value="0">There are no unconfigured locations</option>`);
                            }
                        }).fail((response) => {
                            _self.statusPanel.showFailureStatus(response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
                        });
                    }
                },
                midClick: true
            });
        });

        this.$buttons.confirmSelect.on('click', (e) => {
            $.magnificPopup.close();
            let selectedId = $(e.target).prev().val();

            if (selectedId !== "0") {
                if (this.configType === 'nameplates')
                    this.$host.trigger('clone-item', selectedId);
                else if (this.configType === 'freqbands' || this.configType === 'configurations')
                    this.$host.trigger('generate-item', selectedId);
            }
        });
    }

    createDialogs() {

        this.$dialogs = {
            select: $('body [data-modal-dialog="admin-fft-select"]')
        };

        if (this.$dialogs.select.length)
            this.$dialogs.select.remove();

        $('body').append(
            `<div data-modal-dialog="admin-fft-select" class="mfp-with-anim mfp-hide">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="text-center">
                                <label class="font-weight-bold"></label>
                                <select class="form-control mx-auto my-2">                           
                                </select>
                                <button class="btn btn-dark" data-button="confirm-select">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
        );

        this.$dialogs.select = $('body [data-modal-dialog="admin-fft-select"]');
    }
}