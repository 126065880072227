import $ from 'jquery';
import ConfigEditor from '../configEditor';

export default class FftConfigList extends ConfigEditor {

    constructor(host) {

        super(host);
    }

    bind() {

        super.bind();

        this.$host.on({
            'clone-item': (e, id) => {

                this.$buttons.saveButton.text('Create');

                $.get({
                    url: `/api/admin/${this.apiPath}/${id}`,
                    dataType: "json"
                }).done(data => {
                    let jsData = JSON.parse(data);
                    jsData['id'] = 0;
                    jsData['name'] = jsData['name'] + ' Copy';
                    this.render(jsData);
                }).fail((response) => {
                    this.$editor.removeClass('active');
                    this.statusPanel.showFailureStatus(response.responseJSON && response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
                });
            },
            'generate-item': (e, id) => {
                this.$buttons.saveButton.text('Create');

                let url = '';
                if (this.configType === 'freqbands') {
                    this.saveParams = { nameplate: id };
                    url = `/api/admin/${this.apiPath}/generate/${id}`;
                }
                else if (this.configType === 'configurations') {
                    url = `/api/admin/${this.apiPath}/${id}`;
                    this.selectedItem.id = id;
                }

                $.get({
                    url: url,
                    dataType: "json"
                }).done(data => {
                    let jsData = JSON.parse(data);
                    this.render(jsData);
                }).fail((response) => {
                    this.$editor.removeClass('active');
                    this.statusPanel.showFailureStatus(response.responseJSON && response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
                });
            }
        });
    }

    render(data) {

        if (this.configType === 'configurations') {
            this.renderFftConfigurator(data);
        }
        else {

            if (this.$editor.is('[multi-column]')) {
                let multiColumns = this.$editor.attr('multi-column').split(',');

                multiColumns.forEach(mc => {
                    var column = data[mc];
                    for (var key in column) {
                        let $field = this.$editor.find(`[data-complex=${mc}][name=${key}]`);
                        $field.val(column[key] ? column[key] : '0');
                    }

                    delete data[mc];
                });
            }

            super.render(data);
        }
    }

    renderFftConfigurator(data) {

        let $container = this.$editor.find('.sublocations');

        let selectors = {};
        data['selectors'].forEach(selector => {

            let options = '<option value="0">Not assigned</option>';
            for (let key in selector.options) {
                options += `<option value="${key}">${selector.options[key]}</option>`;
            }

            selectors[selector.name] = `<select class='form-control' name=${selector.name}>${options}</select>`;
        });

        for (let key in data) {
            if (key === 'selectors')
                continue;

            if (key === 'sublocs' && data[key]) {
                $container.empty();

                data[key].forEach(subloc => {

                    let $sublocRow = $(`
                            <div class='form-group row'>
                                <label class='col-sm-4 col-form-label' subloc-id='${subloc.id}'>${subloc.name}</label>
                                <label class='col-sm-3 col-form-label'>${subloc.np}</label>
                                <div class='col-sm-5'>${selectors['freqbandsset']}</div>
                            </div>
                        `);

                    $sublocRow.find('select').val(subloc.fb ? subloc.fb.id : "0");

                    $container.append($sublocRow);
                });

                this.$editor.on('change', 'select', e => {
                    if (e.target.value !== "0") {
                        $.get({
                            url: `/api/admin/fft/nameplates/${e.target.value}`
                        }).done(data => {
                            let np = JSON.parse(data);
                            $(e.target).parent().prev().text(np.name);
                        });
                    }
                    else {
                        $(e.target).parent().prev().text('');
                    }  
                });
            }
            else if (key === 'name') {
                let $field = this.$editor.find(`[name="name"]`);
                $field.text(data[key]);
            }
            else {
                let $field = this.$editor.find(`[name="${key}"]`);
                if ($field.prop('tagName') === 'TEXTAREA')
                    $field.text(data[key]);
                else
                    $field.val(data[key]);
            }
        }
        this.$editor.addClass('active');
    }

    save() {

        if (this.configType === 'configurations') {

            let settings = {};
            this.$elements.editForm.find('.form-group').each((i, el) => {
                let $subloc = $(el);
                let id = $subloc.find('[subloc-id]').attr('subloc-id');
                let fb = $subloc.find('select').val();
                settings[id] = fb;
            });

            super.save({ data: JSON.stringify(settings) });
        }
        else
            super.save();
    }
}