import $ from 'jquery';
import Highcharts from 'highcharts/highcharts';
//import GreyTheme from 'highcharts/themes/gray';

$(function () {

    if ($('#event_list').length) {

        var $event_list = $('#event_list');

        var eventsTable = $event_list.DataTable({
            paging: false,
            order: [[0, "desc"]],
            scrollY: $('.chart-wrapper .h-100').height() - 56.4,
            scrollX: '100%',
            info: false,
            autoWidth: true,
            searching: false,
            language: {
                emptyTable: "There are no active events"
            },
            createdRow: function (row, data, index) {
                $(row).attr('data-event-id', data.eventId);
                $(row).attr('data-preevent-id', data.preEventId);
                $(row).attr('data-postevent-id', data.postEventId);
            },
            initComplete: function (settings, json) {
                $event_list.find('.dataTables_scrollBody').addClass("scrollbar");               
            },
            columns: [
                {
                    data: 'eventDate',
                    fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                        var date = Date.parse(sData);
                        $(nTd).attr("data-order", date).html(sData);
                    }
                },
                { data: 'location' },
                { data: 'sublocation' },
                { data: 'eventType' },
                {
                    data: 'eventStatus',
                    className: 'event-status',
                    fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
                        $(nTd).attr('data-status', sData.toLowerCase()).html(sData);
                    }
                }
            ],
            ajax: {
                url: "/api/events/list",
                type: "GET",
                dataSrc: function (json) {
                    $event_list.data("events", json.length);
                    return json;
                }
            }
        });

        $('#event_list tbody').on('click', function (e) {

            var eventId;
            if (e.target.matches('.event-status')) {

                var currentStatus = $(e.target).attr('data-status');
                eventId = $(e.target).closest('tr').attr('data-event-id');

                $.magnificPopup.open({
                    items: {
                        src: '[data-modal-dialog="event-status"]',
                        type: 'inline'
                    },
                    removalDelay: 300,
                    callbacks: {
                        beforeOpen: function () {
                            this.st.mainClass = 'mfp-zoom-in bg-black';
                        },
                        open: function () {
                            var $content = $(this.content);
                            $content.find(`#${currentStatus}`).prop('checked', true);

                            $('[data-modal-dialog="event-status"] button').on('click', function (e) {
                                if (e.currentTarget.matches('[type="reset"]')) {
                                    $('[data-modal-dialog="event-status"] button').off();
                                    $.magnificPopup.close();
                                }
                                else if (e.currentTarget.matches('[type="submit"]')) {
                                    var newStatus = $('input[name=eventStatus]:checked', '[data-modal-dialog="event-status"]').val();

                                    $.ajax({
                                        url: `${path}api/events/${eventId}/status/${newStatus}`,
                                        type: 'POST',
                                        success: function () {
                                            $('[data-modal-dialog="event-status"] button').off();
                                            $.magnificPopup.close();
                                        }
                                    });
                                }
                            });
                        },
                        close: function () {
                            eventsTable.ajax.reload();
                        }
                    },
                    midClick: true,
                    closeOnBgClick: false,
                    enableEscapeKey: false,
                    modal: true
                });
                return;
            }

            var $tr = $(e.target).closest('tr');
            if ($tr.hasClass('event-charts')) {

                eventId = $(e.target).attr('data-event-id');
                if (eventId === '0')
                    return;

                $('#event_charts').modal('show');
                $('#event_charts .loading').removeClass('d-none');

                $('#event_charts #save_data').data('id', eventId);

                $.ajax({
                    url: '/api/events/' + eventId,
                    type: 'GET',
                    dataType: "json",
                    success: function (data) {

                        $("#event_charts h6").html(data.header);
                        $.each(data.datasets, function (index, dataset) {

                           $(`<div class="chart" id="chart-${index}"></div>`).appendTo('.charts-viewer');
                            Highcharts.chart(`chart-${index}`, {
                                chart: {
                                    marginLeft: 50,
                                    zoomType: 'x',
                                    resetZoomButton: {
                                        theme: {
                                            display: 'none'
                                        }
                                    },
                                    events: {
                                        selection: function (event) {
                                            if (typeof event.xAxis !== typeof undefined)
                                                ZoomAllCharts(event);
                                        }
                                    }
                                },
                                title: {
                                    text: dataset.name,
                                    align: 'right',
                                    verticalAlign: 'top',
                                    x: -35,
                                    style: { color: 'white', fontSize: "22px", fontWeight: 'bold' }
                                },
                                credits: {
                                    enabled: false
                                },
                                legend: {
                                    enabled: true,
                                    align: 'center',
                                    verticalAlign: 'top'
                                },
                                xAxis: {
                                    crosshair: true,
                                    events: {
                                        setExtremes: syncExtremes
                                    },
                                    labels: {
                                        format: '{value} s',
                                        style: { color: 'grey' }
                                    }
                                },
                                yAxis: {
                                    min: dataset.ymin,
                                    max: dataset.ymax,
                                    title: { text: null },
                                    labels: {
                                        style: { color: 'grey' }
                                    }
                                },
                                tooltip: {
                                    shared: true,
                                    useHTML: true,
                                    borderColor: 'black',
                                    borderRadius: 10,
                                    borderWidth: 1,
                                    headerFormat: '<div style="color: white; margin-bottom: 5px;"><b><span  style="float:left;">t:</span>' +
                                        '<span style="float:right;">{point.x} ms</span></b></div></br><table>',
                                    pointFormat: '<tr><td style="color: {series.color}"><b>{series.name}: <b></td>' +
                                        '<td style="text-align: right"><b>{point.y}</b></td></tr>',
                                    footerFormat: '</table>',
                                    shadow: false,
                                    style: {
                                        fontSize: '12px'
                                    },
                                    valueDecimals: 3,
                                    positioner: function (w, h, p) {
                                        return {
                                            x: (function () {
                                                if (p.plotX <= w)
                                                    return p.plotX + 0.8 * w;
                                                else
                                                    return p.plotX - 0.8 * w;
                                            })(),
                                            y: p.plotY
                                        };
                                    }
                                },
                                exporting: {
                                    chartOptions: {
                                        plotOptions: {
                                            series: {
                                                dataLabels: {
                                                    enabled: true
                                                }
                                            }
                                        }
                                    },
                                    scale: 3,
                                    fallbackToExportServer: false,
                                    /*buttons: {
                                        contextButton: {
                                            x: -30,                                
                                        }                              
                                    }*/
                                },
                                series:
                                    [
                                        {
                                            data: (function () {
                                                var serie = [];
                                                $.each(data.xData, function (index, tPoint) {
                                                    serie.push([tPoint, dataset.lines[0].data[index]]);
                                                });
                                                return serie;
                                            })(),
                                            name: 'Phase ' + dataset.lines[0].phase,
                                            states: {
                                                hover: {
                                                    enabled: false,
                                                }
                                            }
                                        },
                                        {
                                            data: (function () {
                                                var serie = [];
                                                $.each(data.xData, function (index, tPoint) {
                                                    serie.push([tPoint, dataset.lines[1].data[index]]);
                                                });
                                                return serie;
                                            })(),
                                            name: 'Phase ' + dataset.lines[1].phase,
                                            states: {
                                                hover: {
                                                    enabled: false
                                                }
                                            }
                                        },
                                        {
                                            data: (function () {
                                                var serie = [];
                                                $.each(data.xData, function (index, tPoint) {
                                                    serie.push([tPoint, dataset.lines[2].data[index]]);
                                                });
                                                return serie;
                                            })(),
                                            name: 'Phase ' + dataset.lines[2].phase,
                                            states: {
                                                hover: {
                                                    enabled: false
                                                }
                                            }
                                        }
                                    ]
                            });
                        });

                        //Event synchronized chart
                        $('.charts-viewer').bind('mousemove touchmove touchstart', function (e) {
                            var chart, points, i;
                            for (i = 0; i < Highcharts.charts.length; i++) {
                                chart = Highcharts.charts[i];
                                if (typeof chart === 'undefined' || chart.options.chart.type === 'column')
                                    continue;

                                e = chart.pointer.normalize(e);
                                points = [chart.series[0].searchPoint(e, true),
                                chart.series[1].searchPoint(e, true),
                                chart.series[2].searchPoint(e, true)];

                                if (points[0] && points[1] && points[2]) {
                                    points[0].onMouseOver();
                                    points[1].onMouseOver();
                                    points[2].onMouseOver();
                                    chart.tooltip.refresh(points);
                                    chart.xAxis[0].drawCrosshair(e, points[0]);
                                }
                            }
                        });

                        $('#reset_zoom').on('click', function (e) {
                            for (var i = 0; i < Highcharts.charts.length; i++) {
                                var chart = Highcharts.charts[i];
                                if (typeof chart === 'undefined' || chart.options.chart.type === 'column')
                                    continue;
                                chart.zoomOut();
                            }

                            $('#reset_zoom').hide();
                        });

                        $('#event_charts .fa-window-close').on('click', function () {
                            $('#event_charts').modal('hide');
                            setTimeout(function () {
                                //modal dialog cleanup
                                for (var i = 0; i < Highcharts.charts.length; i++) {
                                    var chart = Highcharts.charts[i];
                                    if (typeof chart === 'undefined' || chart.options.chart.type === 'column')
                                        continue;

                                    chart.destroy();
                                }
                                $('.charts-viewer').empty();
                                $('#event_charts .modal-header h6').empty();
                            }, 500);
                        });
                    },
                    error: function () {

                    },
                    complete: function () {
                        $('#event_charts .loading').addClass('d-none');
                    }
                });
            }
            else {

                var row = eventsTable.row($tr);

                if (row.child.isShown()) {
                    row.child.hide();
                    $tr.removeClass('shown');
                }
                else {
                    row.child(format($tr[0].attributes)).show();
                    $tr.addClass('shown');

                    if (!$tr.next().hasClass('event-charts'))
                        $tr.next().addClass('event-charts');
                }
            }

            function format(attributes) {

                var preEventId = attributes['data-preevent-id'].value;
                var eventId = attributes['data-event-id'].value;
                var postEventId = attributes['data-postevent-id'].value;

                return `
                    <div class='d-flex justify-content-around px-5 py-1'>
                        <span ${(preEventId === '0' ? 'disabled' : '')} data-event-id=${preEventId}><i class="fas fa-signal mr-2"></i>Pre-event file</span>
                        <span ${(eventId === '0' ? 'disabled' : '')} data-event-id=${eventId}><i class="fas fa-wave-square mr-2"></i>Event file</span>
                        <span ${(postEventId === '0' ? 'disabled' : '')} data-event-id=${postEventId}><i class="fas fa-signal fa-flip-horizontal mr-2"></i>Post-event file</span>
                    </div>
                `;
            }
        });

      
        $('#save_data').on('click', function () {
            var eventId = $(this).data('id');

            for (var i = 0; i < Highcharts.charts.length; i++) {
                var chart = Highcharts.charts[i];
                if (typeof chart === 'undefined' || chart.options.chart.type === 'column')
                    continue;

                activeChart = chart;
                break;
            }

            $('#file_downloader').attr('src', `${path}api/events/${eventId}/downloadcsv?sIndex=${chart.series[0].points[0].index}&length=${chart.series[0].points.length}`);
        });

        //autorefresh
        autoRefresh();
        setInterval(autoRefresh, 10000);
    }

    function autoRefresh() {
        $.ajax({
            url: "/api/events/list",
            type: "GET",
            success: function (json) {
                var currentCount = $event_list.data("events");
                if (currentCount !== json.length) {
                    $('#event_list').data("events", json.length);
                    eventsTable.ajax.reload();
                }
            }
        });
    }

    function ZoomAllCharts(event) {
        var minX = event.xAxis[0].min;
        var maxX = event.xAxis[0].max;
        var thisChart = event.target;

        Highcharts.each(Highcharts.charts, function (chart) {
            if (typeof chart !== 'undefined' && chart.options.chart.type !== 'column') {
                if (chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes)
                        chart.xAxis[0].setExtremes(minX, maxX);
                }
            }
        });

        $('#reset_zoom').show();
    }

    function syncExtremes(e) {
        if (typeof chart === 'undefined' || chart.options.chart.type === 'column')
            return;

        var thisChart = this.chart;
        Highcharts.each(Highcharts.charts, function (chart) {
            if (typeof chart !== 'undefined' && chart.options.chart.type !== 'column') {
                if (chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes)
                        chart.xAxis[0].setExtremes(e.min, e.max);
                }
            }
        });
    }
});