import $ from 'jquery';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';

export default class GeneratorOutput {

    constructor() {

        this.selectors = {
            page: '#generator_output_cms',
            toolbarButtons: {
                scanParams: '#scan_parameters_btn',
                tooltips: '#tooltips_btn',
                exportData: '#export_data_btn'
            },
            dialogs: {
                scanParams: '#scan_parameters',
                tooltips: '#tooltips',
                exportData: '#export_data'
            },
            alertTable: '#alerts_table'
        }

        this.startDate = moment().add(-1, 'M');
        this.endDate = moment();

        this.scanSettings = {
            refStartDate: moment().add(-1, 'M'),
            refEndDate: moment(),
            evalEndDate: moment(),
            bucketSize: 7,
            stdDev: 5,
            limitRed: 0.21
        };

        this.init();
    }

    init() {

        let $page = $(this.selectors.page);

        this.$elements = {
            page: $page,
        };

        this.$dialogs = {
            scanParams: this.$elements.page.find(this.selectors.dialogs.scanParams),
            tooltips: this.$elements.page.find(this.selectors.dialogs.tooltips),
            exportData: this.$elements.page.find(this.selectors.dialogs.exportData)
        };

        //---- should go into global file
        $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
            icons: {
                time: 'fas fa-clock',
                date: 'fas fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'fas fa-trash-alt',
                close: 'fas fa-times'
            }
        });
        //-------------------

        $(this.selectors.alertTable).DataTable({
            paging: false,
            order: [[0, "desc"]],
            //scrollY: $('.chart-wrapper .h-100').height() - 56.4,
            //scrollX: '100%',
            info: false,
            autoWidth: true,
            searching: false,
            ordering: false,
            //processing: true,
            serverSide: true,
            language: {
                emptyTable: "There are no active alerts"
            },
            //createdRow: function (row, data, index) {
            //    $(row).attr('data-event-id', data.eventId);
            //    $(row).attr('data-preevent-id', data.preEventId);
            //    $(row).attr('data-postevent-id', data.postEventId);
            //},
            initComplete: function (settings, json) {
                //$event_list.find('.dataTables_scrollBody').addClass("scrollbar");
            },
            columns: [
                { data: 'location' },
                { data: 'sublocation' },
                { data: 'load' },
                { data: 'reference' },
                { data: 'frequency' },
                { data: 'type' },
                { data: 'alarm' },
                { data: 'current' },
                { data: 'timeRange1' },
                { data: 'timeRange2' },
                { data: 'timeRange3' },
                { data: 'timeRange4' },
                { data: 'watch' },
                { data: 'assign' },
                { data: 'scan' }
            ],
            //columns: [
            //    //{
            //    //    data: 'eventDate',
            //    //    fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            //    //        var date = Date.parse(sData);
            //    //        $(nTd).attr("data-order", date).html(sData);
            //    //    }
            //    //},
            //    //{ data: 'location' },
            //    //{ data: 'sublocation' },
            //    //{ data: 'eventType' },
            //    //{
            //    //    data: 'eventStatus',
            //    //    className: 'event-status',
            //    //    fnCreatedCell: function (nTd, sData, oData, iRow, iCol) {
            //    //        $(nTd).attr('data-status', sData.toLowerCase()).html(sData);
            //    //    }
            //    //}
            //],
            ajax: {
                url: "/analytics/generator-output/alerts",
                type: "POST",
                data: () => {
                    return JSON.stringify({
                        refStartDate: this.scanSettings.refStartDate.unix(),
                        refEndDate: this.scanSettings.refEndDate.unix(),
                        evalEndDate: this.scanSettings.evalEndDate.unix(),
                        bucketSize: this.scanSettings.bucketSize,
                        stdDev: this.scanSettings.stdDev,
                        limitRed: this.scanSettings.limitRed
                    });
                },
                dataSrc: function (json) {
                    //$event_list.data("events", json.length);
                    console.log("ajax dataSrc: " + json);
                    return json;
                }
            }
        });

        this.bind();
    }

    bind() {

        //----- Datetime picker ---------------------------------------
        $('#refStartDate').datetimepicker({
            defaultDate: this.scanSettings.refStartDate
        });

        $('#refEndDate').datetimepicker({
            defaultDate: this.scanSettings.refEndDate
        });

        //$('#startDate').on("change.datetimepicker", (e) => {
        //    $('#endDate').datetimepicker('minDate', e.date);
        //    $('#startDate').datetimepicker('hide');
        //    this.startDate = e.date;

        //    this.renderCharts();
        //});
        //$('#endDate').on("change.datetimepicker", (e) => {
        //    $('#startDate').datetimepicker('maxDate', e.date);
        //    $('#endDate').datetimepicker('hide');
        //    this.endDate = e.date;

        //    this.renderCharts();
        //});
        //--------------------------------------------------------

        $(this.selectors.toolbarButtons.scanParams).on('click', e => {

            this.renderScanParamsDialog();

        });
    }

    renderScanParamsDialog() {

        let _self = this;

        $.magnificPopup.open({
            items: {
                src: $(this.selectors.dialogs.scanParams),
                type: 'inline'
            },
            removalDelay: 300,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = 'mfp-zoom-in';
                },
                open: function () {

                    console.log(this);

                    //let $content = $(this);

                    this.content.on('click', e => {

                        if (e.target.matches('[type="submit"]')) {
                            console.log('submit clicked');


                            //if (_self.selectedDeviceId) {
                            //    _self.renderCharts();
                            //}

                            $.magnificPopup.close();
                        }
                        else if (e.target.matches('[type="reset"]')) {
                            //console.log('cancel clicked');
                            $.magnificPopup.close();
                        }
                    });

                    //$(targetDialog).on('change', e => {

                    //    if (e.currentTarget.matches(_self.selectors.chartSettingsDialog)) {

                    //        _self.chartFilters = {};
                    //        $(e.currentTarget).find('[type="checkbox"]').each((i, el) => {
                    //            let $el = $(el);
                    //            _self.chartFilters[$el.attr('name')] = $el.is(':checked');

                    //        });
                    //    }
                    //    else if (e.currentTarget.matches(_self.selectors.chartDataDialog)) {
                    //        _self.chartData = $(e.currentTarget).find('[type="radio"]:checked').val();
                    //    }
                    //});
                },
                afterClose: function () {
                    //console.log('afterClose called');
                    //$(targetDialog).off();
                }
            },
            midClick: true,
            closeOnBgClick: false,
            enableEscapeKey: false,
            modal: true
        });

    }


}