// ----------------------- SCSS ---------------------------

//npm
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'magnific-popup/dist/magnific-popup.css';

//site
import '../css/_index.scss';

// ----------------------- JS ---------------------------

// NPM
import 'popper.js/dist/popper';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-plugins/features/pageResize/dataTables.pageResize';
import 'magnific-popup/dist/jquery.magnific-popup';
import 'highcharts/highcharts';
import 'highcharts/highstock';
import 'highcharts/modules/data';
import 'highcharts/modules/drilldown';
import 'highcharts/modules/exporting';
import 'highcharts/themes/gray';
import 'jquery-validation/dist/jquery.validate';

// Admin
import './admin';
import './admin/data-manager';
import './admin/superadmin';

// Dashboard
import './dashboard/comm-errors';
import './dashboard/event-history';
import './dashboard/event-list';

// File Manager
import './utilities/fileManager';

// Settings
import './settings';

//Script manager
import './scriptManager';

//Shared
import './shared/utilities';
import './shared/clock';

import './login-form';
import './site';

