import $ from 'jquery';

export default class StatusPanel {

    constructor() {

        this.panelTemplate = `
            <div id="status_panel">
                <div class="status-success">
                    <h6>Success!</h6>
                    <p></p>
                    <div class="status-icon">
                        <i class="fas fa-check"></i>
                    </div>
                    <div class="status-progress">
                    </div>
                </div>
                <div class="status-failure">
                    <h6>Failure!</h6>
                    <p></p>
                    <div class="status-icon">
                        <i class="fas fa-times"></i>
                    </div>
                    <div class="status-progress">
                    </div>
                </div>
            </div>
        `;

        this.selectors = {
            panel: '#status_panel',
            successDiv: '.status-success',
            failureDiv: '.status-failure',
            progress: '.status-progress'
        };

        this.initialize();
    }

    initialize() {

        this.$panel = $(this.selectors.panel);
        if (!this.$panel.length) {
            const $body = $('.body');
            $body.prepend($(this.panelTemplate));
            this.$panel = $body.find(this.selectors.panel).first();
        }

        this.$components = {
            successDiv: this.$panel.find(this.selectors.successDiv),
            failureDiv: this.$panel.find(this.selectors.failureDiv)
        };

        this.bind();
    }

    bind() {
        this.$components.successDiv.on('click', (e) => {
            this.$components.successDiv.removeClass('show');
        });
        this.$components.failureDiv.on('click', (e) => {
            this.$components.failureDiv.removeClass('show');
        });
    }

    startTimer(message) {
        this.$activeStatusBlock.find('p').html(message);
        this.$activeStatusBlock.addClass('show');

        setTimeout(() => { }, 2000);

        var i = 100;
        var progressBar = this.$activeStatusBlock.find('.status-progress');
       
        this.intervalId = setInterval(() => {
            i--;
            progressBar.css('width', `${i}%`);
            if (i === 0) {
                this.$activeStatusBlock.removeClass('show');
                clearInterval(this.intervalId);
            }
        }, 30);
    }

    showSuccessStatus(message) {
        this.$activeStatusBlock = this.$components.successDiv;
        this.startTimer(message);
    }

    showFailureStatus(message) {
        this.$activeStatusBlock = this.$components.failureDiv;
        this.startTimer(message);
    }
}