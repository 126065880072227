import $ from 'jquery';

export default class FileUploader {

    constructor(configuration) {

        this.$caller = configuration.caller;
        this.allowMultiple = configuration.allowMultiple ? 'multiple' : '';

        this.selectors = {    
            dialog: '[data-modal-dialog="file-uploader"]',
            cancelButton: '[data-button="upload-cancel"]',
            confirmButton: '[data-button="upload-confirm"]',
            fileName: '[data-role="fileName"]',
            fileInput: '[data-role="filePath"]'
        };

        this.dialogTemplate = `
            <div data-modal-dialog="file-uploader" class="white-popup mfp-with-anim position-relative mfp-hide">
                <h6>${configuration.title}</h6>
                <form data-url>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" data-role="filePath" accept="${configuration.filter}" ${this.allowMultiple}>
                        <label class="custom-file-label" for="customFile" data-role="fileName">Choose file(s)</label>
                    </div>
                    <div class="mt-5 justify-content-end d-flex">
                        <button type="button" class="btn btn-dark px-3 mr-3 d-none" data-button="upload-confirm">Upload</button>
                        <button type="button" class="btn btn-dark" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Uploading...
                        </button>
                        <button type="button" class="btn btn-outline-secondary px-3" data-button="upload-cancel">Cancel</button>
                    </div>
                </form>
            </div>
        `;

        this.$dialog = {};
        this.$buttons = {};
        this.$elements = {};

        this.apiUrl = '';
        this.mfp;

        this.init();
    }

    init() {

        this.$dialog = $(this.selectors.dialog);
        if (!this.$dialog.length) {
            const $body = $('.body');
            $body.append($(this.dialogTemplate));
            this.$dialog = $body.find(this.selectors.dialog).first();
        }

        this.$buttons = {
            cancel: this.$dialog.find('[data-button="upload-cancel"]'),
            confirm: this.$dialog.find('[data-button="upload-confirm"]')
        };

        this.$elements = {
            fileName: this.$dialog.find('[data-role="fileName"]'),
            fileInput: this.$dialog.find('[data-role="filePath"]')
        };

        this.bind();
    }

    bind() {

       

        this.$buttons.cancel.on('click', (e) => {
            this.close();           
        });

        this.$buttons.confirm.on('click', (e) => {
            if (this.apiUrl && this.selectedFiles.length) {
                this.$dialog.addClass('uploading');
                this.$elements.fileInput.prop('disabled', true);

                let formData = new FormData();
                $.each(this.selectedFiles, function (i, file) {
                    formData.append('file[' + i + ']', file, file.name);
                })

                $.ajax({
                    url: this.apiUrl,
                    type: 'POST',
                    enctype: 'multipart/form-data',
                    processData: false,  // Important!
                    contentType: false,
                    cache: false,
                    data: formData,
                    beforeSend: xhr => {
                        if (this.selectedFiles.length === 1) {
                            let file = this.selectedFiles[0];
                            let lastModified = file.lastModified > 2147483647 ? Math.round(file.lastModified / 1000) : file.lastModified;
                            xhr.setRequestHeader('X-FBC-Timestamp', lastModified);
                        }
                    },                        
                    success: () => {
                        let result = {
                            success: true,
                            message: this.selectedFiles.length === 1 ? `<span>${this.selectedFiles[0].name}</span> has been uploaded` : `<span>${this.selectedFiles.length}</span> files have been uploaded`
                        };

                        this.$caller.trigger('file-uploaded', result);
                    },
                    error: (response) => {
                        let result = {
                            success: false,
                            message: response && response.responseJSON ? response.responseJSON : `Unable to upload file(s)` //<span>${this.fileName}</span>
                        };

                        this.$caller.trigger('file-uploaded', result);                       
                    },
                    complete: () => {
                        this.$dialog.removeClass('uploading');
                        this.close();
                    }
                });
            }
        });

        this.$elements.fileInput.on({
            'change': (e) => {
                if (e.target.files.length) {   

                    this.selectedFiles = e.target.files;

                    if (e.target.files.length > 1)
                        this.$elements.fileName.text(e.target.files.length + " files selected");
                    else 
                        this.$elements.fileName.text(e.target.files[0].name);
                    
                    this.$buttons.confirm.removeClass('d-none');
                }
            }
        });
    }

    open(apiUrl) {
        this.apiUrl = apiUrl;

        if (!this.mfp) {
            this.mfp = $.magnificPopup.open({
                items: {
                    src: '[data-modal-dialog="file-uploader"]',
                    type: 'inline'
                },
                mainClass: 'mfp-zoom-in',
                removalDelay: 500,              
                midClick: true,
                closeOnBgClick: false,
                enableEscapeKey: false,
                modal: true
            });
        }
        else {           
            $.magnificPopup.open({
                items: {
                    src: '[data-modal-dialog="file-uploader"]',
                    type: 'inline'
                }
            });
        }
    }

    close() {
        this.$elements.fileName.text('Choose file');
        this.$buttons.confirm.addClass('d-none');
        this.$elements.fileInput.prop('disabled', false);
        this.$elements.fileInput.val('');
        $.magnificPopup.close();
    }
}