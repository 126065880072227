import $ from 'jquery';

export default class ControlPanel {

    constructor(panel) {

        this.$panel = panel;

        this.seletors = {
            menuLink: '.panel-link'
        };

        this.init();
    }

    init() {

        this.$links = this.$panel.find(this.seletors.menuLink);

        this.bind();       
    }

    bind() {

        this.$panel.find('div').on('shown.bs.collapse', (e) => {
            $(e.target).removeClass('activating');
        });

        this.activate();
    }

    activate() {
        let pathname = window.location.pathname;
        let $link = this.$links.filter(`[href="${pathname}"]`);
        if ($link.length) {
            $link.addClass('activated');
            if ($link.parent().prop('tagName') === 'DIV') {
                $link.parent().addClass('activating');
                $link.parent().collapse('show');
                $link.parent().prev().addClass('activated');
            }
            else if ($link.parent().prop('tagName') === 'LI') {
                $link.parent().addClass('activated');
            }
        }        
    }
}