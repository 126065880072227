import $ from 'jquery';
import StatusPanel from './shared/statusPanel';

$(function () {
    if ($('[name="login-form"]').length) {
        var statusPanel = new StatusPanel();

        $('[name="login-form"] input').on('focus', function () {
            $(this).closest('.input-group').addClass('focus-in');
        });

        $('[name="login-form"] input').on('blur', function () {
            $(this).closest('.input-group').removeClass('focus-in');
        });

        $('[name="login-form"]').on('click', 'button[type=submit]', function (e) {
            e.preventDefault();

            $.ajax({
                url: '/login',
                type: 'POST',
                data: $('[name = "login-form"] form').serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.IsSuccess) {
                        location.href = data.Url;
                    }
                    else {
                        var $errorDiv = $('[name = "login-form"] .error-message');
                        $errorDiv.html('').removeClass('d-none');
                        $(`<i class="fas fa-exclamation-circle mr-2"></i><label>${data.ErrorMessage}</label>`).appendTo($errorDiv);
                    }
                }
            });
        });
    }
});

