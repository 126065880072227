import $ from 'jquery';
import Highcharts from 'highcharts/highcharts';
import GreyTheme from 'highcharts/themes/gray';

$(function () {

    GreyTheme(Highcharts);

    //TODO: move to global level
    Highcharts.setOptions({
        colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
    });

    Highcharts.Pointer.prototype.reset = function () {
        return undefined;
    };

    if ($('#dashboard').length) {

        updateStatusPanel();

        setInterval(function () {
            updateStatusPanel();
        }, 10000);
    }

    function updateStatusPanel() {
        $.ajax({
            url: '/api/status',
            type: 'GET',
            dataType: "json",
            success: function (response) {
                $("#devicesTotal").text(response.devicesTotal);
                $("#devicesOffline").text(response.devicesOffline);
                $("#outagesTotal").text(response.outagesTotal);
                $("#commErrors").text(response.commErrors);
                $("#eventsTotal").text(response.newEvents);
            }
        });
    }

    $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
    });

    $('.control-panel').on('click', '.disabled', function (e) {
        e.preventDefault();
    });
});