import $ from 'jquery';
import ConfigList from './configList';
import ConfigViewer from './configViewer';
import ConfigEditor from './configEditor';
import FftConfigList from './fft/fftConfigList';
import FftConfigViewer from './fft/fftConfigViewer';
import FftConfigEditor from './fft/fftConfigEditor';

$(function () {

    if ($('.admin-config').length) {
        
        if ($('#rotating_machinery_settings').length) {
            let configList = new FftConfigList($('#nameplates'));
            let configViewer = new FftConfigViewer($('#nameplates'));
            let configEditor = new FftConfigEditor($('#nameplates'));

            configList.bind();
            configViewer.bind();
            configEditor.bind();

            $('.nav-link').on('click', (e) => {
                var tab = e.target.getAttribute('href');
                configList = new FftConfigList($(tab));
                configViewer = new FftConfigViewer($(tab));
                configEditor = new FftConfigEditor($(tab));

                configList.bind();
                configViewer.bind();
                configEditor.bind();
            });       
        }
        else {
            let configList = new ConfigList($('.admin-config'));
            let configViewer = new ConfigViewer($('.admin-config'));
            let configEditor = new ConfigEditor($('.admin-config'));  

            configList.bind();
            configViewer.bind();
            configEditor.bind();
        }
    }    
});