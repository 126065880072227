import $ from 'jquery';
import ConfigViewer from '../configViewer';

export default class FftConfigViewer extends ConfigViewer {

    constructor(host) {

        super(host);
    }

    bind() {

        super.bind();

        this.$host.on('generate-item', () => {
            super.close();
        });
    }

    render() {

        if (this.configType === 'configurations') {

            let $container = this.$viewer.find('.sublocations');

            for (let key in this.selectedItem) {
                if (key === 'selectors')
                    continue;

                if (key === 'sublocs' && this.selectedItem[key]) {
                    $container.empty();

                    this.selectedItem[key].forEach(subloc => {

                        let $sublocRow = $(`
                            <div class='row'>
                                <label class='col-sm-4'>${subloc.name}</label>
                                <label class='col-sm-4'>${subloc.np}</label>
                                <label class='col-sm-4'>${subloc.fb.name}</label>
                            </div>
                        `);

                        $container.append($sublocRow);
                    });
                }
                else if (key === 'name') {
                    let $field = this.$viewer.find(`[name="name"]`);
                    $field.text(this.selectedItem[key]);
                }
                else {
                    let $field = this.$viewer.find(`[name="${key}"]`);
                    if ($field.prop('tagName') === 'TEXTAREA')
                        $field.text(this.selectedItem[key]);
                    else
                        $field.val(this.selectedItem[key]);
                }
            }
            this.$viewer.addClass('active');
        }
        else if (this.$viewer.is('[multi-column]')) {

            let tempStorage = JSON.stringify(this.selectedItem);

            let multiColumns = this.$viewer.attr('multi-column').split(',');
            multiColumns.forEach(mc => {
                var column = this.selectedItem[mc];
                for (var key in column) {
                    let search = `[data-complex='${mc}'][data-field='${key}']`;
                    let $field = this.$viewer.find(search);
                    $field.text(column[key] ? column[key] : '0');
                }

                delete this.selectedItem[mc];
            });

            super.render();

            this.selectedItem = JSON.parse(tempStorage);
        }
        else
            super.render();
    }
}