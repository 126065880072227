import $ from 'jquery';
import FBTFileViewer from '../shared/file-viewers/fbtFileViewer';

export default class LastActivityTracker {

    constructor() {

        this.bind();

        new FBTFileViewer();
    }

    bind() {

        var fileTable = $("#device_activity table").DataTable({
            dom: '<frt<t>p>',
            columns: [
                { data: 'location' },
                { data: 'sublocation' },
                { data: 'device' },
                {
                    data: 'lastActivity',
                    type: 'date',
                },
                {
                    data: 'fileId',
                    orderable: false
                },
            ],
            pageResize: true,
            order: [[3, "desc"]]
        });
    }
}