import $ from 'jquery';
import StatusPanel from '../shared/statusPanel';

export default class ConfigList {

    $host;
    $list;
    $elements;
    $buttons;
    $dialogs;

    selectors;
    configType;
    apiPath;
    statusPanel;

    constructor(host) {  

        this.$host = host;

        this.selectors = {
            list: '[data-role="list"]',
            createButton: '[data-button="create"]',
            searchInput: '[data-search]',
            noResults: '.no-results'
        };

        this.$list = this.$host.find(this.selectors.list);

        this.configType = this.$host.attr('data-type');
        this.apiPath = this.$host.attr('data-path');

        this.statusPanel = new StatusPanel();

        this.$elements = {
            searchInput: this.$host.find(this.selectors.searchInput),
            noResults: this.$host.find(this.selectors.noResults)
        };

        this.$buttons = {
            create: this.$host.find(this.selectors.createButton)
        };
    }

    bind() {

        //----- List clicks
        this.$list.on('click', 'li', (e) => {
            e.preventDefault();

            if ($(e.target).hasClass('blank-list'))
                return;

            $('.admin-config').animate({ scrollTop: 0 }, 200);

            this.$host.trigger("item-selected", e.target.getAttribute('data-id'));
            this.$list.find('.selected').removeClass('selected');
            $(e.target).addClass('selected');
        });
       
        //----- Add new instance
        this.$buttons.create.on('click', (e) => {
            this.$host.trigger('create-item');
        });

        //----- Filter list
        this.$elements.searchInput.on('input', (e) => {
            let filteredIds = [];
            this.$list.find('li').not(this.$elements.noResults).each((i, elem) => {
                if (elem.innerText.indexOf(e.target.value) !== -1) {
                    $(elem).removeClass('d-none');
                    filteredIds.push(elem.getAttribute('data-id'));
                }
                else {
                    $(elem).addClass('d-none');
                }
            });

            if (filteredIds.length === 0)
                this.$elements.noResults.removeClass('d-none');
            else
                this.$elements.noResults.addClass('d-none');

            this.$host.trigger('filter-applied', filteredIds);
        });

        //----- Event listeners
        this.$host.on({

            'item-created': () => {
                this.render();
            },

            'item-updated': () => {
                this.render();
            },

            'item-deleted': () => {
                this.render();
            }
        });
    }   
    
    render() {

        $.get({
            url: `/api/admin/${this.apiPath}`,
            dataType: "json"
        }).done(data => {
            this.$list.empty();

            var elements = $.parseJSON(data);
            if (elements.length !== 0) {
                elements.forEach(element => {
                    this.$list.append(`<li data-id="${element.id}">${element.name}</li>`);
                });

                this.$list.on('click', 'li', (e) => {
                    this.$host.trigger("item-selected", e.target.getAttribute('data-id'));
                    this.$list.find('.selected').removeClass('selected');
                    $(e.target).addClass('selected');
                });
            }
            else {
                this.$list.append(`<li class="blank-list">There are no active ${this.configType}</li>`);
            }
        }).fail((response) => {
            this.statusPanel.showFailureStatus(response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
        });
    }   
}