import $ from 'jquery';

$(function () {
    var $dialog = $('[data-modal-dialog="organization-editor"]');
    var orgId = 0;

    $('[data-action="add"]').on('click', function (e) {        
        $.ajax({
            url: '/superadmin/organization',
            type: 'GET'
        }).done(function (response) {
            renderDialogBox(response);
        });        
    });

    $('[data-action="edit"]').on('click', function (e) {
        orgId = $(this).closest('li').attr('data-org-id');

        $.ajax({
            url: '/superadmin/organization/' + orgId,
            type: 'GET'
        }).done(function (response) {
            renderDialogBox(response);
        });

    });

   

    function renderDialogBox(response) {
        $dialog.html(response);
        $dialog.find('.alert').addClass('d-none');

        $.magnificPopup.open({
            items: {
                src: $dialog,
                type: 'inline'
            },
            removalDelay: 300,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = 'mfp-zoom-in';
                },
                open: function () {

                    $dialog
                        .on('change', '#changeAdminPassword', function (e) {
                            $('.admin-password').slideToggle(300);
                        })
                        .on('change', '#changeAdminEmail', function (e) {
                            $('.admin-email').slideToggle(300);
                        });

                    $dialog
                        .on('click', 'button[type=reset]', function (e) {
                            //$dialog.off();
                            $.magnificPopup.close();
                        })
                        .on('click', '[action="reset-password"]', function (e) {
                            e.preventDefault();

                            var $button = $(this);

                            var orgId = $button.closest('form').find('[name="orgId"]').val();

                            $.ajax({
                                url: '/superadmin/organization/' + orgId + '/password',
                                type: 'POST'
                            }).done(function (response) {
                                if (response.success) {
                                    $button.html('<i class="fas fa-check"></i> DONE')
                                    setInterval(function () { $button.text('Reset Password'); }, 2500);
                                    
                                }
                            });
                        });

                    $dialog.on('submit', 'form', function (e) {
                        e.preventDefault();

                        $dialog.addClass('saving');

                        var orgId = $(this).closest('li').attr('data-org-id');
                        var data = $dialog.find('form').serialize();
                        data += '&changeAdminPassword=' + $('#changeAdminPassword').prop('checked');
                        data += '&changeAdminEmail=' + $('#changeAdminEmail').prop('checked');

                        $.ajax({
                            url: "organization/" + orgId,
                            type: 'POST',
                            data: data
                        }).done(function (response) {
                            if (response.success) {

                                if (response.isNewOrg) {
                                    $('#super_admin_portal ul').append(
                                        `<li class="py-3" data-org-id="${response.orgId}">
                                            <div class="card shadow">
                                                <div class="row">
                                                    <div class="col-2 p-3 d-flex justify-content-center align-items-center">
                                                        <i class="fas fa-user-tie fa-2x"></i>
                                                    </div>
                                                    <div class="col-8 p-3 d-flex align-content-start align-items-start">
                                                        <h6 class="mb-0">${response.orgName}</h6>
                                                    </div>
                                                    <div class="col-2 py-3 px-0 d-flex justify-content-center align-items-center">
                                                        <i data-action="edit" class="fas fa-edit fa-lg mr-2"></i>
                                                        <i data-action="delete" class="fas fa-trash-alt fa-lg"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>`
                                    );
                                }

                                $.magnificPopup.close();
                            }
                            else {
                                let $errorDiv = $dialog.find('.alert');
                                $errorDiv.html(response.responseText);
                                $errorDiv.removeClass('d-none');
                            }
                        }).fail(function (response) {
                            $errorDiv = $dialog.find('.alert');
                            $errorDiv.html(response.responseText ? response.responseText : "Unable to process your request. Please try again");
                            $errorDiv.removeClass('d-none');
                        }).always(function () {
                            $dialog.removeClass('saving');
                        });
                    });
                },
                close: function () {
                    $dialog.off();
                }
            },
            midClick: true,
            closeOnBgClick: false,
            enableEscapeKey: false, 
            modal: true
        });
    }
});