import $ from 'jquery';

$(function () {
    updateClock();
    setInterval(updateClock, 1000);

    function updateClock() {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var currentTime = new Date();
        var currenday = currentTime.getDate();
        var currentMonth = monthNames[currentTime.getMonth()];
        var currentYear = currentTime.getFullYear();
        var currentHours = currentTime.getHours();
        var currentMinutes = currentTime.getMinutes();
        var currentSeconds = currentTime.getSeconds();

        // Pad the minutes and seconds with leading zeros, if required
        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
        currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;

        // Choose either "AM" or "PM" as appropriate
        var timeOfDay = (currentHours < 12) ? "AM" : "PM";

        // Convert the hours component to 12-hour format if needed
        currentHours = (currentHours > 12) ? currentHours - 12 : currentHours;

        // Convert an hours component of "0" to "12"
        currentHours = (currentHours === 0) ? 12 : currentHours;

        // Compose the string for display
        var currentTimeString = currenday + " " + currentMonth + " " + currentYear + " " +
            currentHours + ":" + currentMinutes + ":" + currentSeconds + " " + timeOfDay;

        $("#clock").text(currentTimeString);
    }
});