import $ from 'jquery';
import formToJson from '../shared/utilities';
import StatusPanel from '../shared/statusPanel';

export default class ConfigEditor {

    $host;
    $editor;
    $elements;
    $buttons;

    selectors;
    configType;
    statusPanel;

    selectedItem;
    saveParams;

    constructor(host) {

        this.$host = host;

        this.selectors = {
            editor: '[data-role="editor"]',
            editForm: '[data-role="editor"] form',
            saveButton: '[data-button="save"]',
            cancelButton: '[data-button="cancel"]',
            dropdown: 'select[data-bind]'
        };

        this.$editor = this.$host.find(this.selectors.editor);

        this.configType = this.$host.attr('data-type');
        this.apiPath = this.$host.attr('data-path');

        this.selectedItem = { id: 0 };
        this.statusPanel = new StatusPanel();

        this.$buttons = {
            saveButton: this.$host.find(this.selectors.saveButton),
            cancelButton: this.$host.find(this.selectors.cancelButton)
        };
             
        this.$elements = {
            editForm: this.$host.find(this.selectors.editForm),
            dropdowns: this.$host.find(this.selectors.dropdown)
        };

        this.saveParams = null;
    }

    bind() {

        this.$host.on({

            'create-item': () => {
                this.$buttons.saveButton.text('Create');
                this.$elements.editForm.trigger('reset');
            },

            'item-selected': (e, id) => {
                this.$editor.removeClass('active');
            },

            'item-edit': (e, selectedItem) => {
                this.selectedItem = selectedItem;
                this.$buttons.saveButton.text('Update');
                this.render(selectedItem);
            },

            'filter-applied': () => {
                this.$editor.removeClass('active');
            },

            'item-deleted': () => {
                this.$editor.removeClass('active');
            },

            'item-created': () => {
                this.$editor.removeClass('active');
            }
        });

        this.$elements.dropdowns.on('change', (e) => {
            let $elem = $(e.target);
            let source = $elem.prop('name');
            let target = $elem.attr('data-bind');

            $.get({
                url: `/api/admin/${source}s/${e.target.value}/${target}s`
            }).done( data => {

                let $targetElem = this.$host.find(`[name="${target}"]`);
                $targetElem.empty();

                let options = JSON.parse(data);
                for (let option in options) {
                    $targetElem.append(`<option value=${option}>${options[option]}</option>`);
                }

                $targetElem.prop('disabled', false);
            });
        });

        this.$buttons.saveButton.on('click', (e) => {
            e.preventDefault();

            this.save();
        });

        this.$buttons.cancelButton.on('click', (e) => {
            this.$editor.removeClass('active');
        });

        this.$elements.editForm.on('reset', (e) => {
            $.get({
                url: `/api/admin/default-settings/${this.configType}`,             
            }).done(response => {
                this.render(JSON.parse(response));
            }).fail((response) => {
                this.statusPanel.showFailureStatus(response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
            });
        });              
    }

    render(data) {
       
        for (let key in data) {
            if (key === 'selectors' && data[key]) {
                data[key].forEach(selector => {
                    let $elem = this.$editor.find(`[name="${selector.name}"]`);
                    if (selector.type === 'select') {
                        $elem.empty();

                        if (selector.isDisabled && !selector.selected)
                            $elem.prop('disabled', true);

                        if (!selector.selected)
                            $elem.append(`<option selected>${selector.defaultValue}</option>`);

                        for (let key in selector.options) {
                            $elem.append(`<option ${selector.selected === key ? 'selected' : ''} value="${key}">${selector.options[key]}</option>`);
                        }
                    }
                });
            }         
            else if (data[key] && typeof data[key] === 'object') {
                var complexKey = data[key];
                for (var key2 in complexKey) {
                    let $field = this.$editor.find(`[name="${key2}"]`);
                    if ($field.prop('tagName') === 'TEXTAREA')
                        $field.text(complexKey[key2]).attr('data-complex', key);
                    else
                        $field.val(complexKey[key2]).attr('data-complex', key);
                }
            }
            else {
                let $field = this.$editor.find(`[name="${key}"]`);
                if ($field.prop('tagName') === 'TEXTAREA')
                    $field.text(data[key]);
                else
                    $field.val(data[key]);
            }
        }
        this.$editor.addClass('active');
    }

    save(params) {

        if (!params)
            params = {};

        if (!params.url) {
            params.url = `/api/admin/${this.apiPath}/${this.selectedItem.id}`;
            if (this.saveParams) {
                params.url += '?';
                for (let param in this.saveParams) {
                    params.url += `${param}=${this.saveParams[param]}&`;
                }
                params.url = params.url.substring(0, params.url.length - 1);
            }
        }

        if (!params.data)
            params.data = formToJson(this.$elements.editForm.find('.form-control'));

        $.post({
            url: params.url,
            data: params.data,
            contentType: "application/json"
        }).done(response => {

            this.statusPanel.showSuccessStatus(response);
            this.$editor.removeClass('active');

            if (this.saveParams)
                this.saveParams = null;

            if (this.$buttons.saveButton.text() === 'Update')
                this.$host.trigger('item-updated');
            else
                this.$host.trigger('item-created');

        }).fail((response) => {
            this.statusPanel.showFailureStatus(response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
        });
    }
}