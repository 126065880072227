import { data } from 'highcharts';
import $ from 'jquery';

$(function () {

    console.log("test");

    $('#data-magement [type="checkbox"]').on('change', function () {
        console.log($(this).is(':checked'));
        $.post({
            url: '/admin/data-management',
            data: { save: $(this).is(':checked') },
            success: function () {
                location.reload();
            }
        });
    });
});