import $ from 'jquery';

if ($('#comm_errors').length) {
    var fileTable = $("#comm_errors table").DataTable({
        dom: '<frt<t>p>',
        columns: [
            { data: 'location' },
            { data: 'sublocation' },
            { data: 'device' },
            { data: 'file' },
            { data: 'errorMessage' },
            {
                data: 'reported',
                type: 'date'
            }
        ],
        pageResize: true,      
        order: [[5, "desc"]]
    });
}