import $ from 'jquery'
import Highcharts, { attr } from 'highcharts/highcharts';

export default class FBTFileViewer {

    apiEndpoint = '/api/file/data/';

    template =
        `<div data-modal-dialog="file-viewer" class="black-popup mfp-with-anim mfp-hide position-relative">
            <div class="loading">
                <img src="/Content/img/gears.gif" />
            </div>
            <div class="custom-content">
                <div class="custom-header">
                    <h6></h6>
                </div>
                <div class="custom-body">

                </div>
            </div>
        </div>`;


    $dialogHeader;
    $dialogBody;

    charts = [];

    constructor() {

        this.selectors = {
            activator: 'fbd-file-viewer',
            dialog: '[data-modal-dialog="file-viewer"]'
        };

        this.init();
    }

    init() {

        let $dialog = $(this.selectors.dialog);

        if (!$dialog.length)
            $dialog = $(this.template).appendTo('body');

        this.$dialogHeader = $dialog.find('.custom-header h6');
        this.$dialogBody = $dialog.find('.custom-body');

        this.bind();
    }

    bind() {

        $(`[${this.selectors.activator}]`).on('click', (e) => {

            let _self = this;

            var fileId = $(e.currentTarget).attr(this.selectors.activator);
            console.log('fileId: ' + fileId);

            $.magnificPopup.open({
                items: {
                    src: this.selectors.dialog,
                    type: 'inline'
                },
                removalDelay: 300,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = 'mfp-zoom-in bg-black';
                    },
                    open: function () {

                        var $content = $(this.content);

                        $content.removeClass('initialized');


                        $.ajax({
                            url: `${_self.apiEndpoint}${fileId}`,
                            success: function (data) {
                                $content.find('h6').html(`<span>File:</span>${data.fileName}<span>Type:</span>${data.fileType}<span>Rate:</span>${data.rate} Hz<span>Location:</span>${data.location}<span>Sublocation:</span>${data.sublocation}<span>Local Time:</span>${data.started}<span>Status:</span>${data.processMessage}`);

                                $.each(data.channels, function (chIndex, channel) {
                                    var series = [];
                                    $.each(channel.phases, function (phIndex, phase) {
                                        series[phIndex] = {
                                            name: 'Phase ' + phase.name,
                                            data: (function () {
                                                var dpSet = [];
                                                $.each(phase.dataPoints, function (index, dataPoint) {
                                                    dpSet.push([1 / data.rate * index, parseFloat(dataPoint)]);
                                                });

                                                return dpSet;
                                            })()
                                        };
                                    });

                                    $(`<div class="chart" id="chart-${chIndex}"></div>`).appendTo(_self.$dialogBody);
                                    var chart = Highcharts.chart(`chart-${chIndex}`, {
                                        chart: {
                                            marginRight: 40,
                                            marginLeft: 60,
                                            width: _self.$dialogBody.width(),
                                            zoomType: 'x',
                                            //resetZoomButton: {
                                            //    theme: {
                                            //        display: 'none'
                                            //    }
                                            //},
                                            events: {
                                                //selection: function (event) {
                                                //    if (typeof event.xAxis !== typeof undefined)
                                                //        ZoomAllCharts(event);
                                                //}
                                                //load: function (event) {
                                                //    event.target.reflow();
                                                //}
                                            }
                                        },
                                        title: {
                                            text: channel.name,
                                            align: 'right',
                                            verticalAlign: 'top',
                                            x: -15,
                                            style: { color: 'white', fontSize: "22px", fontWeight: 'bold' }
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        legend: {
                                            enabled: true,
                                            align: 'center',
                                            verticalAlign: 'top'
                                        },
                                        xAxis: {
                                            crosshair: true,
                                            minPadding: 0,
                                            maxPadding: 0,
                                            //events: {
                                            //    setExtremes: syncExtremes
                                            //},
                                            labels: {
                                                format: '{value} s',
                                                style: { color: 'grey' }
                                            }
                                        },
                                        yAxis: {
                                            //min: dataset.ymin,
                                            //max: dataset.ymax,
                                            title: { text: null },
                                            labels: {
                                                style: { color: 'grey' }
                                            }
                                        },
                                        tooltip: {
                                            shared: true,
                                            useHTML: true,
                                            borderColor: 'black',
                                            borderRadius: 10,
                                            borderWidth: 1,
                                            headerFormat: '<div style="color: white; margin-bottom: 5px;"><b><span  style="float:left;">t:</span>' +
                                                '<span style="float:right;">{point.x} s</span></b></div></br><table>',
                                            pointFormat: '<tr><td style="color: {series.color}"><b>{series.name}: <b></td>' +
                                                '<td style="text-align: right"><b>{point.y}</b></td></tr>',
                                            footerFormat: '</table>',
                                            shadow: false,
                                            style: {
                                                fontSize: '12px'
                                            },
                                            valueDecimals: 3,
                                            positioner: function (w, h, p) {
                                                return {
                                                    x: (function () {
                                                        if (p.plotX <= w)
                                                            return p.plotX + 0.8 * w;
                                                        else
                                                            return p.plotX - 0.8 * w;
                                                    })(),
                                                    y: p.plotY
                                                };
                                            }
                                        },
                                        exporting: {
                                            enabled: false
                                            //chartOptions: {
                                            //    plotOptions: {
                                            //        series: {
                                            //            dataLabels: {
                                            //                enabled: true
                                            //            }
                                            //        }
                                            //    }
                                            //},
                                            //scale: 3,
                                            //fallbackToExportServer: false
                                        },
                                        series: series
                                    });

                                    _self.charts.push(chart);
                                });

                                $content.addClass('initialized');
                            }
                        });
                    },
                    close: function () {
                        _self.charts.forEach(function (chart) {
                            chart.destroy();
                        });

                        _self.charts = [];

                        _self.$dialogHeader.html('');
                        _self.$dialogBody.html('');
                    }
                },
                midClick: true,
                closeOnBgClick: false,
                enableEscapeKey: false
            });
        })
    }
}
