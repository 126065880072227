import $ from 'jquery';
//import StatusPanel from './shared/statusPanel';
import CustomSelect from './shared/dropdown';
import ControlPanel from './shared/controlPanel';
import GeneratorOutput from './analytics/generatorOutput';
import LastActivityTracker from './utilities/lastActivityTracker';
import RealTimeDataViewer from './utilities/realTimeDataViewer';
import PowerOutageTracker from './utilities/powerOutageTracker';

$(function () {

    //if ($('.status-panel')) {
    //    var statusPanel = new StatusPanel($('.status-panel'));
    //    statusPanel.showSuccessStatus('test message');
    //}

    if ($('.control-panel')) {
        new ControlPanel($('.control-panel'));
    }

    if ($('#generator_output_cms').length) {
        new GeneratorOutput();
    }

    if ($('.div-select').length) {
        new CustomSelect();
    }

    if ($('#device_activity').length) {
        new LastActivityTracker();
    }

    if ($('#power_outage_tracker').length) {
        new PowerOutageTracker();
    }

    if ($('#real_time_data_viewer').length) {
        new RealTimeDataViewer($('#real_time_data_viewer'));
    }
});