import Highcharts from 'highcharts/highcharts';
import GreyTheme from 'highcharts/themes/gray';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';

export default class RealTimeDataViewer {

    apiEndpoint = '/api/data/';

    $page;
    selectors;
    $elements;

    startDate;
    endDate;

    charts = [];
    chartFilters = {};
    chartData = 'average';

    selectedDeviceId;

    constructor(page) {

        this.$page = page;

        this.selectors = {
            device: '[name="device"]',
            chartContainer: '#chart_container',
            loader: '.loading',
            filterButton: '[data-role="filter"]',
            exportButton: '[data-role="export"]',
            chartSettingsDialog: '#parameters',
            chartDataDialog: '#values'
        }

        this.init();
    }

    init() {

        GreyTheme(Highcharts);

        this.$elements = {
            device: this.$page.find(this.selectors.device),
            chartContainer: this.$page.find(this.selectors.chartContainer),
            loader: this.$page.find(this.selectors.loader),
            filterButton: $(this.selectors.filterButton),
            exportButton: $(this.selectors.exportButton)
        }

        //---- should go into global file
        $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
            icons: {
                time: 'fas fa-clock',
                date: 'fas fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'fas fa-trash-alt',
                close: 'fas fa-times'
            }
        });
        //-------------------

        this.startDate = moment().add(-1, 'M');
        this.endDate = moment();

        //---- init chart filters
        $(this.selectors.chartSettingsDialog).find('[type="checkbox"]').each((i, el) => {
            let $el = $(el);
            this.chartFilters[$el.attr('name')] = $el.is(':checked');
        });

        this.bind();
    }

    bind() {

        //----- Datetime picker ---------------------------------------
        $('#startDate').datetimepicker({
            defaultDate: this.startDate
        });
        $('#endDate').datetimepicker({
            defaultDate: this.endDate
        });

        $('#startDate').on("change.datetimepicker", (e) => {
            $('#endDate').datetimepicker('minDate', e.date);
            $('#startDate').datetimepicker('hide');
            this.startDate = e.date;

            this.renderCharts();
        });
        $('#endDate').on("change.datetimepicker", (e) => {
            $('#startDate').datetimepicker('maxDate', e.date);
            $('#endDate').datetimepicker('hide');
            this.endDate = e.date;

            this.renderCharts();
        });
        //--------------------------------------------------------

      
        //----- Toolbar button events
        this.$elements.filterButton.on('click', e => {

            let _self = this;

            let targetDialog = $(e.currentTarget).attr('data-target');

            $.magnificPopup.open({
                items: {
                    src: targetDialog,
                    type: 'inline'
                },
                removalDelay: 300,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = 'mfp-zoom-in';
                    },
                    open: function () {

                        $(targetDialog).on('click', e => {

                            if (e.target.matches('[type="submit"]')) {
                                console.log('submit clicked');
                               

                                if (_self.selectedDeviceId) {
                                    _self.renderCharts();
                                }     

                                $.magnificPopup.close();
                            }
                            else if (e.target.matches('[type="reset"]')) {
                                console.log('cancel clicked');
                                $.magnificPopup.close();
                            }
                        });

                        $(targetDialog).on('change', e => {

                            if (e.currentTarget.matches(_self.selectors.chartSettingsDialog)) {

                                _self.chartFilters = {};
                                $(e.currentTarget).find('[type="checkbox"]').each((i, el) => {
                                    let $el = $(el);
                                    _self.chartFilters[$el.attr('name')] = $el.is(':checked');

                                });
                            }
                            else if (e.currentTarget.matches(_self.selectors.chartDataDialog)) {
                                _self.chartData = $(e.currentTarget).find('[type="radio"]:checked').val();
                            }
                        });
                    },
                    afterClose: function () {
                        console.log('afterClose called');
                        $(targetDialog).off();
                    }
                },
                midClick: true,
                closeOnBgClick: false,
                enableEscapeKey: false,
                modal: true
            });
        });

        this.$elements.exportButton.on('click', e => {

            if (!this.selectedDeviceId)
                return;

            let _self = this;

            $.ajax({
                url: `${this.apiEndpoint}${this.selectedDeviceId}/download`,
                type: 'POST',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({
                    startDate: this.startDate.unix(),
                    endDate: this.endDate.unix(),
                    chartFilters: _self.chartFilters,
                    chartData: _self.chartData
                }),
                success: function (response, status, xhr) {

                    var filename = "";
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches !== null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    var blob = new Blob([response], { type: 'text/csv' });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                }
            });
        });
        //--------------------------------------------------------

        this.$elements.device.on('click', (e) => {

            this.$page.find('.active').removeClass('active');
            let $device = $(e.currentTarget);
            $device.addClass('active');

            $device.closest('[name="location"]').find('h5').addClass('active');
            $device.closest('ul').prev().addClass('active');

            this.selectedDeviceId = $device.closest('li').attr('data-device-id');

            this.renderCharts();
        });

    }

    renderCharts() {

        this.$elements.loader.removeClass('d-none');

        this.disposeResources();

        let _self = this;

        $.ajax({
            url: `${this.apiEndpoint}${this.selectedDeviceId}`,
            type: 'POST',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify({
                startDate: this.startDate.unix(),
                endDate: this.endDate.unix(),
                chartFilters: _self.chartFilters,
                chartData: _self.chartData
            }),
            success: function (response) {

                if (response.data) {

                    response.data.forEach(parameter => {
                        _self.$elements.chartContainer.append(`<div id=${parameter.name} class="device-chart m-2 p-2"></div>`);

                        var series = [];
                        parameter.phases.forEach(phase => {
                            series.push({
                                name: phase.name,
                                data: (function () {
                                    var serie = [];
                                    serie.push([_self.startDate.unix() * 1000, null]);
                                    $.each(phase.dataPoints, function (index, point) {
                                        serie.push([point.timeStamp, parseFloat(point.value)]);
                                    });
                                    serie.push([_self.endDate.unix() * 1000, null]);
                                    return serie;
                                })()
                            });
                        });

                        var chart = new Highcharts.Chart({
                            chart: {
                                renderTo: parameter.name,
                                marginRight: 40,
                                marginLeft: 60,
                                //width: _self.ele$dialogBody.width(),
                                zoomType: 'x',
                                //resetZoomButton: {
                                //    theme: {
                                //        display: 'none'
                                //    }
                                //},
                                events: {
                                    //selection: function (event) {
                                    //    if (typeof event.xAxis !== typeof undefined)
                                    //        ZoomAllCharts(event);
                                    //}
                                    //load: function (event) {
                                    //    event.target.reflow();
                                    //}
                                }
                            },
                            title: {
                                text: `${parameter.name} (${_self.chartData})`,
                                align: 'right',
                                verticalAlign: 'top',
                                x: -15,
                                style: { color: 'white', fontSize: "22px", fontWeight: 'bold' }
                            },
                            credits: {
                                enabled: false
                            },
                            legend: {
                                enabled: true,
                                align: 'center',
                                verticalAlign: 'top'
                            },
                            xAxis: {
                                type: 'datetime',
                                crosshair: true,
                                minPadding: 0,
                                maxPadding: 0,
                                //events: {
                                //    setExtremes: syncExtremes
                                //},
                                //labels: {
                                //    formatter: function () {
                                //        return Highcharts.dateFormat('%Y %M %d', this.value);
                                //    }
                                //}
                                //}
                            },
                            yAxis: {
                                //min: dataset.ymin,
                                //max: dataset.ymax,
                                title: { text: null },
                                labels: {
                                    style: { color: 'grey' }
                                }
                            },
                            tooltip: {
                                shared: true,
                                useHTML: true,
                                borderColor: 'black',
                                borderRadius: 10,
                                borderWidth: 1,
                                formatter: function () {

                                    var pointsData = this.points.map(p => {
                                        return `<tr>
                                                    <td style="color: ${p.color}">${p.series.name}: </td><td>${p.y.toFixed(3)}</td>
                                                </tr>`
                                    }).join('');

                                    return `<table> 
                                                <tr><td class="pr-2">DATE:</td><td>${moment.unix(this.x / 1000).format("MM/DD/YYYY")}</td></tr>
                                                <tr><td class="pr-2">TIME:</td><td>${moment.unix(this.x / 1000).format("HH:mm:ss A")}</td></tr>
                                                ${pointsData}
                                            </table>`;
                                },
                                shadow: false,
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                },
                                positioner: function (w, h, p) {
                                    return {
                                        x: (function () {
                                            if (p.plotX <= w)
                                                return p.plotX + 0.8 * w;
                                            else
                                                return p.plotX - 0.8 * w;
                                        })(),
                                        y: p.plotY
                                    };
                                }
                            },
                            exporting: {
                                enabled: false
                                //chartOptions: {
                                //    plotOptions: {
                                //        series: {
                                //            dataLabels: {
                                //                enabled: true
                                //            }
                                //        }
                                //    }
                                //},
                                //scale: 3,
                                //fallbackToExportServer: false
                            },
                            series: series
                        });

                        _self.charts.push(chart);
                    });
                }
                
                _self.$elements.loader.addClass('d-none');
            },
            error: function (response) {
                if (response.status === 404) {
                    _self.$elements.chartContainer.append(`<div id="message">${response.responseText}</div>`);
                }
                    
                else {
                    _self.$elements.chartContainer.append(`<div id="message" class="error-message">${response.responseText}</div>`);
                }
                    
                _self.$elements.loader.addClass('d-none');
            }
        });
    }

    disposeResources() {

        this.$elements.chartContainer.empty();

        this.charts.forEach(function (chart) {
            chart.destroy();
        });

        this.charts = [];
    }
}