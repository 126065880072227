import $ from 'jquery';
import StatusPanel from '../shared/statusPanel';
import FileUploader from '../shared/fileUploader';

export default class ConfigViewer {

    $host;
    $viewer;
    $buttons;
    $dialogs;

    selectors;
    configType;
    apiPath;
    selectedItem;
    statusPanel;
    fileUploader;

    constructor(host) {

        this.$host = host;
        
        this.selectors = {
            viewer: '[data-role="viewer"]',       
            editButton: '[data-button="edit"]',
            //delete config entity
            deleteButton: '[data-button="delete"]',
            deleteDialog: '[data-modal-dialog="admin-delete"]',
            cancelDeleteButton: '[data-button="delete-cancel"]',
            confirmDeleteButton: '[data-button="delete-confirm"]',
            //upload file
            uploadFileButton: '[data-button="upload"]'
        };

        this.$viewer = this.$host.find(this.selectors.viewer);

        this.configType = this.$host.attr('data-type');
        this.apiPath = this.$host.attr('data-path');

        this.statusPanel = new StatusPanel();

        this.createDialogs();

        this.$buttons = {
            edit: this.$host.find(this.selectors.editButton),
            //delete
            delete: this.$host.find(this.selectors.deleteButton),
            cancelDelete: this.$dialogs.delete.find(this.selectors.cancelDeleteButton),
            confirmDelete: this.$dialogs.delete.find(this.selectors.confirmDeleteButton),
            //upload file
            uploadFile: this.$host.find(this.selectors.uploadFileButton)
        };

        if (this.configType === 'devices') {
            this.fileUploader = new FileUploader({
                title: 'Upload configuration file',
                filter: '.fbc',
                caller: this.$buttons.uploadFile
            });
        }

        this.selectedItem = {};
    }

    bind() {
       
        this.$host.on({

            'create-item': () => {
                this.close();
            },

            'clone-item': () => {
                this.close();
            },
           
            'item-selected': (e, id) => {
                this.update(id);              
            },

            'item-updated': () => {
                this.update(this.selectedItem.id);
            },

            'filter-applied': (e, filteredIds) => {
                if (!filteredIds || !filteredIds.includes(this.selectedItem.Id)) {
                    this.close();  
                }
            }
        });
        
        this.$buttons.edit.on('click', (e) => {
            this.$host.trigger('item-edit', this.selectedItem);
        });

        //delete 
        this.$buttons.delete.on('click', (e) => {
            var _self = this;

            $.magnificPopup.open({
                items: {
                    src: _self.selectors.deleteDialog,
                    type: 'inline'
                },
                removalDelay: 300,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = 'mfp-zoom-in';
                    },
                    open: function () {
                        var $content = $(this.content);
                        $content.find('p span').text(_self.selectedItem.name);
                    }
                },
                midClick: true,
                closeOnBgClick: false,
                enableEscapeKey: false,
                modal: true
            });
        });

        this.$buttons.cancelDelete.on('click', (e) => {
            $.magnificPopup.close();
        });

        this.$buttons.confirmDelete.on('click', (e) => {
            $.ajax({
                url: `/api/admin/${this.apiPath}/${this.selectedItem.id}`,
                type: 'DELETE'
            }).done(response => {
                this.$host.trigger('item-deleted');
                this.close();
                this.statusPanel.showSuccessStatus(response);                
            }).fail((response) => {
                this.statusPanel.showFailureStatus(response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
            }).always(() => {
                $.magnificPopup.close();
            });
        });
     
        //upload file
        this.$buttons.uploadFile.on({

            'click': (e) => {
                if (this.configType === 'devices') {
                    let serialNumber = this.$viewer.find('[data-field="name"]').text();
                    this.fileUploader.open(`/api/config/${serialNumber}/upload`);
                }
            },

            'file-uploaded': (e, result) => {
                if (result.success)
                    this.statusPanel.showSuccessStatus(result.message);
                else
                    this.statusPanel.showFailureStatus(result.message);
            }
        });
    }

    update(id) {

        $.get({
            url: `/api/admin/${this.apiPath}/${id}`,
            dataType: "json"
        }).done(data => {
            this.selectedItem = JSON.parse(data);
            this.render();
        }).fail((response) => {
            this.$viewer.removeClass('active');
            this.statusPanel.showFailureStatus(response.responseJSON && response.responseJSON.length ? response.responseJSON : 'Unable to process your request');
        });
    }

    render(){

        for (var key in this.selectedItem) {
            if (key === 'selectors')
                continue;

            if (typeof (this.selectedItem[key] && this.selectedItem[key]) === 'object') {
                var complexKey = this.selectedItem[key];
                for (var key2 in complexKey) {
                    let $field = this.$viewer.find(`[data-field="${key2}"]`);
                    if (complexKey[key2]) {
                        $field.parent().removeClass('d-none');
                        $field.text(complexKey[key2]);
                    }
                    else {
                        $field.parent().addClass('d-none');
                    }
                }
            }
            else {
                let $field = this.$viewer.find(`[data-field="${key}"]`);
                if (this.selectedItem[key]) {
                    $field.parent().removeClass('d-none');
                    $field.text(this.selectedItem[key]);
                }
                else {
                    $field.parent().addClass('d-none');
                }
            }
        }

        this.$viewer.addClass('active');
    }

    close() {
        this.selectedItem = { id: 0 };
        this.$viewer.removeClass('active');
    }

    createDialogs() {

        this.$dialogs = {
            delete: $('body [data-modal-dialog="admin-delete"]')
        };

        if (this.$dialogs.delete.length)
            this.$dialogs.delete.remove();

        $('body').append(
            `<div data-modal-dialog="admin-delete" class="mfp-with-anim mfp-hide position-relative">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <i class="fas fa-exclamation-triangle fa-3x"></i>
                            <h3>Are you sure?</h3>
                            <p>Doing this will permanently remove <span></span></p>
                        </div>
                        <div class="modal-footer">
                            <div class="d-flex justify-content-center mt-4">
                                <button class="btn btn-dark mr-2" data-button="delete-confirm">Confirm</button>
                                <button class="btn btn-outline-secondary" data-button="delete-cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
        );

        this.$dialogs.delete = $('body [data-modal-dialog="admin-delete"]');
    }
}