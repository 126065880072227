import $ from 'jquery';
import Highcharts from 'highcharts/highcharts';
//import GreyTheme from 'highcharts/themes/gray';

$(function () {   

    var historyCharts = [];

    $('#event_history').carousel(0);

    $("#event_history").on('slid.bs.carousel', function (e) {       

        if (!historyCharts[e.to]) {
            var containerId = e.relatedTarget.childNodes[1].getAttribute('id');
            var chartType = e.relatedTarget.childNodes[1].getAttribute('data-chart-type');
            var subtitle = e.relatedTarget.childNodes[1].getAttribute('data-chart-subtitle');
            historyCharts[e.to] = createHistoryChart(containerId, chartType, subtitle);
        }
    });

    //autorefresh
    autoRefresh();
    setInterval(autoRefresh, 10000);

    function autoRefresh() {
        historyCharts.forEach(function (chart) {
            requestHistoricalData(chart.renderTo.getAttribute('data-chart-type'), chart);
        });
    }

    function createHistoryChart(containerId, chartType, subtitleText) {
        
        return new Highcharts.Chart({
            chart: {
                renderTo: containerId,
                type: 'column',
                backgroundColor: 'black',
                events: {
                    load: function () {
                        requestHistoricalData(chartType, this);
                    },
                    drilldown: function (e) {
                        updateTrendingDrilldowns(chartType, this, e.point);
                    },
                    drillup: function (e) {
                        refreshTrending = true;
                    }
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Event History',
                style: {
                    color: '#FF0000',
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            },
            subtitle: {
                text: subtitleText,
                style: {
                    color: '#FF0000',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: '#606060',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: '# of Events',
                    style: {
                        color: '#7CBBC6',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                },
                labels: {
                    style: {
                        color: '#FFFFFF',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {

                series: {

                    borderWidth: 0,
                    dataLabels: {
                        color: '#FFFFFF',
                        enabled: true,
                        format: '{point.y:.0f}',
                        shadow: true,

                        style: {
                            fontSize: '14px'
                        }
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b><br/>'
            },
            series: [
                {
                    name: 'Top',
                    colorByPoint: true,                  
                    data: [
                        {
                            name: 'Current wk',
                            id: 0,
                            drilldown: true
                        }, {
                            name: 'week 1',
                            id: 1,
                            drilldown: true
                        }, {
                            name: 'week 2',
                            id: 2,
                            drilldown: true
                        }, {
                            name: 'week 3',
                            id: 3,
                            drilldown: true
                        }, {
                            name: 'week 4',
                            id: 4,
                            drilldown: true
                        }, {
                            name: 'week 5',
                            id: 5,
                            drilldown: true
                        }
                    ]
                }
            ],
            drilldown: {
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        y: 0,
                        x: 0
                    }
                },
                activeAxisLabelStyle: {
                    color: '#606060',
                    fontSize: '12px',
                    fontWeight: 'bold'
                },
                series: []
            }
        });
    }

    function requestHistoricalData(chartType, chartObject) {

        $.ajax({
            url:  '/api/events/history/' + chartType,
            type: 'GET',
            dataType: "json",
            success: function (data) {
                var allZeroes = true;
                for (var i = 0; i < data.length; i++) {
                    if (data[i] > 0) {
                        allZeroes = false;
                        break;
                    }
                }
                if (allZeroes) {
                    chartObject.yAxis[0].setExtremes(0, 10);                   
                }

                chartObject.series[0].setData(data);                
            }
        });
    }
         
    function updateTrendingDrilldowns(chartType, chartObject, weekObject) {        

        if (!weekObject.seriesOptions) {
            $.ajax({
                url: "/api/events/history/" + chartType + "/" + weekObject.id,
                type: 'GET',
                success: function (data) {
                    var series = [];
                    for (var i = 0; i < data.length; i++) {
                        var serie = new Array(data[i].key, data[i].value);
                        series.push(serie);
                    }

                    var result = {
                        name: weekObject.name,
                        data: series
                    };

                    chartObject.hideLoading();
                    chartObject.addSeriesAsDrilldown(weekObject, result);
                }
            });
        }
    }
});



